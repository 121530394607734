import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './components/shared/menu/menu.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { RouterModule, Routes } from '@angular/router';
import { SharingService } from './services/sharing.service';
import { ApserviceService } from './services/apservice.service';
import { PagesComponent } from './components/pages/pages.component';
import { ServicesComponent } from './components/services/services.component';
import { PromotionsComponent } from './components/promotions/promotions.component';
import { WorkshopCentersComponent } from './components/centers/workshop-centers.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PartnersComponent } from './components/partners/partners.component';
import { MapComponent } from './components/centers/map/map.component';
import { PageDetailsComponent } from './components/pages/page-details/page-details.component';
import { FileUploadModule } from 'ng2-file-upload';
import { ModifyCenterComponent } from './components/centers/modify-center/modify-center.component';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { InlineSVGModule } from 'ng-inline-svg';
import { SocialnetworksComponent } from './components/socialnetworks/socialnetworks.component';
import { PoliciesandconditionsComponent } from './components/policiesandconditions/policiesandconditions.component';
import { AppconfigService } from './services/appconfig.service';
import { HomeDetailsComponent } from './components/pages/home-details/home-details.component';
import { SliderModifyComponent } from './components/pages/home-details/slider-modify/slider-modify.component';
import { BannerModifyComponent } from './components/pages/home-details/banner-modify/banner-modify.component';
import { ConfigurationSiteComponent } from './components/configuration/configuration-site/configuration-site.component';
import { VoComponent } from './components/vo/vo.component';
import { VovehicleComponent } from './components/cards/vovehicle/vovehicle.component';
import { VoformComponent } from './components/forms/voform/voform.component';
import { YearOnlyPipe } from './pipes/year-only.pipe';
import { LoginComponent } from './components/pages/login/login.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {OverlayModule} from "@angular/cdk/overlay";

const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: 'login/:tenant', component: LoginComponent},
  { path: 'login/:tenant/:company', component: LoginComponent},
  { path: 'configuracion/:id', component: ConfigurationComponent},
  { path: 'paginas', component: PagesComponent},
  { path: 'vo', component: VoComponent},
  { path: 'servicios', component: ServicesComponent},
  { path: 'colaboradores', component: PartnersComponent},
  { path: 'promociones', component: PromotionsComponent},
  { path: 'centros', component: WorkshopCentersComponent},
  { path: 'social', component: SocialnetworksComponent},
  { path: 'politicasycondiciones', component: PoliciesandconditionsComponent},
  // { path: 'configuracion', component: ContactComponent, canActivate: [AuthGuardService]},
  { path: '**', pathMatch: 'full', redirectTo: '/configuracion' }
];

const options: NotifierOptions = {
  position: {
      horizontal: {
          position: 'right',
          distance: 12
      },
      vertical: {
          position: 'bottom',
          distance: 50,
          gap: 10
      }
  },
  theme: 'material',
  behaviour: {
      autoHide: 3500,
      onClick: false,
      onMouseover: 'pauseAutoHide',
      showDismissButton: true,
      stacking: 4
  },
  animations: {
      enabled: true,
      show: {
          preset: 'slide',
          speed: 300,
          easing: 'ease'
      },
      hide: {
          preset: 'fade',
          speed: 300,
          easing: 'ease',
          offset: 50
      },
      shift: {
          speed: 300,
          easing: 'ease'
      },
      overlap: 150
  }
};


@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    ConfigurationComponent,
    PagesComponent,
    ServicesComponent,
    PromotionsComponent,
    WorkshopCentersComponent,
    HeaderComponent,
    PartnersComponent,
    MapComponent,
    PageDetailsComponent,
    ModifyCenterComponent,
    SocialnetworksComponent,
    PoliciesandconditionsComponent,
    HomeDetailsComponent,
    SliderModifyComponent,
    BannerModifyComponent,
    ConfigurationSiteComponent,
    VoComponent,
    VovehicleComponent,
    VoformComponent,
    YearOnlyPipe,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    DragDropModule,
    AngularEditorModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    FontAwesomeModule,
    BrowserModule,
    InlineSVGModule.forRoot(),
    NotifierModule.withConfig(options),
    AppRoutingModule,
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'ignore'}),
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
    RouterModule.forRoot(routes, {useHash: true}),
    CarouselModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatSnackBarModule,
    OverlayModule

  ],
  providers: [SharingService,
              ApserviceService,
              AppconfigService,
              {provide: LOCALE_ID, useValue: 'es'
            }],
  bootstrap: [AppComponent]
})
export class AppModule { }
