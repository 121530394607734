import {Component, Input, OnInit, OnChanges, ViewChild, OnDestroy} from '@angular/core';
import {SharingService} from "../../../services/sharing.service";
import {ApserviceService} from "../../../services/apservice.service";
import { Page } from 'src/app/models/page';
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {FileUploader} from "ng2-file-upload";
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-page-details',
  templateUrl: './page-details.component.html',
  styleUrls: ['./page-details.component.scss']
})
export class PageDetailsComponent implements OnInit, OnChanges, OnDestroy {

  isuploadingProgress;
  uploadStatusProgress;
  saveWithImage = false;
  saveSubscription;
  @ViewChild('selectedPicture') selectedPicture: any;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Introduce una descripción...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };

  @Input() idPageType;
  page: Page = {
    id: null,
    id_page_type: null,
    id_workshop: null,
    id_brand: null,
    active: false,
    name: "",
    banner_title: "",
    banner_image: "",
    content_title: "",
    content_description: "",
    content_image: ""
  };

  filename;
  uploader:FileUploader;

  constructor(
    public _ss: SharingService,
    public _ap: ApserviceService,
    private _ns: NotifierService
  ) {
    this.uploader = new FileUploader({
      url: this._ss.adminURL + 'upload',
      method: "POST",
      queueLimit: 1,
      allowedMimeType: ['image/png', 'image/jpeg'],
      itemAlias: 'photo',
    });
   }

  ngOnInit(): void {
    this.saveSubscription = this._ss.$save.subscribe(() => {
      this.save();
    });

      //override the onAfterAddingfile property of the uploader so it doesn't authenticate with //credentials.

      this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false;
        this.filename = file.file.name;
        if (this.uploader.queue.length >= 1) {
          this.saveWithImage = true;
        }
      };

      this.uploader.onBeforeUploadItem = (file)=>{
        console.log(file);
      };

       //overide the onCompleteItem property of the uploader so we are
       //able to deal with the server response.

       this.uploader.onProgressItem = (fileItem: any, progress: any)=>{
        this.isuploadingProgress = true;
         this.uploadStatusProgress = progress+'%';
       }

       this.uploader.onCompleteItem = (item:any, data:any, status:any, headers:any) => {
          data = JSON.parse(data);
         if(data.succes === 'OK'){
          console.log(data);
            let filename = data.file.filename;
            this.page.banner_image = filename;
            this.savePage();
            }else{
              console.log("Se ha producido un error");
            }
          }

            this.uploader.onErrorItem = (item: any, response: string, status: number, headers:any) =>{
              console.log(response);
            }
  }

  ngOnChanges() {
    this.page =  {
      id: null,
      id_page_type: null,
      id_workshop: null,
      id_brand: null,
      active:false,
      name: "",
      banner_title: "",
      banner_image: "",
      content_title: "",
      content_description: "",
      content_image: ""
    }
    this.page.id_page_type = this.idPageType;
    this._ap.getPageDetailByPageTypeForWorkshop(this.idPageType).subscribe({
      next: response => {
        if (response.result == 'OK') {
          console.log(response.msg);
          console.log(response);
          if (response.data.length > 0) {
            this.page = response.data[0];
          }
          console.log(this.page);
        } else {
          console.log("Se ha producido un error");
          console.log(response.msg);
        }
      }
    });
  }

  clearSelectedPicture() {
    this.filename = '';
    this.isuploadingProgress = '';
    this.uploadStatusProgress = '';
    this.selectedPicture.nativeElement.value = '';
    this.uploader.clearQueue();
    this.saveWithImage = false;
  }

  savePage(){
      this._ap.setPageDetails(this.page).subscribe({
        next: (response) => {
          if (response.result == 'OK') {
            this._ns.notify("success", "Guardado correctamente!");
            console.log(response.msg);
            this.clearSelectedPicture();
          } else {
            this._ns.notify("error", "Error: Se ha producido un error en el guardado!");
            console.log(response.msg);
            console.log("Se ha producido un error");
          }
        }
      });
  }

  save(){
    console.log(this.saveWithImage);
    if(this.saveWithImage){
      this.uploader.uploadAll();
    }else{
      this.savePage();
    }
  }

  ngOnDestroy(){
    this.saveSubscription.unsubscribe();
  }
}
