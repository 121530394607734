<div class="login-body">
  <div class="login">
    <mat-spinner *ngIf="loading" class="spinner" diameter="50"></mat-spinner>
    <div class="login-logo-div">
      <img class="login-logo" src="./assets/image/logodriverhub.png" width="300">
      <!--<p class="garage-title-login">Gestión de Webs</p>-->
    </div>

    <form class="login-form" *ngIf="!loading" [formGroup]="form" (ngSubmit)="enterLogin()">
      <mat-form-field class="login-input" appearance="fill">
        <mat-label>Tenant</mat-label>
        <input class="login-input-input" type="text" matInput autocomplete="off" formControlName="tenant">
      </mat-form-field>
      <mat-form-field class="login-input" appearance="fill">
        <mat-label>Usuario</mat-label>
        <input (input)="clearErrorMessage()" class="login-input-input" type="text" matInput autocomplete="off" formControlName="user">
      </mat-form-field>
      <mat-form-field class="login-input" appearance="fill">
        <mat-label>Contraseña</mat-label>
        <input (input)="clearErrorMessage()" class="login-input-input" type="password" matInput autocomplete="off" formControlName="password">
      </mat-form-field>
      <br>
      <p *ngIf="loginErrorMsg" class="login-error-msg">{{loginErrorMsg}}</p>
      <button type="submit" mat-raised-button [disabled]="form.invalid" class="login-button">Enviar
      </button>

    </form>

  </div>
  <p class="login-page-title">Plataforma Digital Driver360</p>
</div>
