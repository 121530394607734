import { Component, OnInit, ViewChild, ElementRef, NgZone, Input, OnChanges } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

// Declarar la variable global para Google
declare var google: any;

let markerId = 0;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnChanges {

  @Input() center;

  place = {
    address: [],
    latitude: null,
    longitude: null,
  };
  title: string;
  draggable = false;
  iconUrl: string;
  visible = true;
  openInfoWindow = true;
  opacity = 1;
  zIndex = 1;
  clickable = true;
  zoom: number = 8;

  private geoCoder;

  showAlert = true;
  faClose = faTimes;

  constructor(private ngZone: NgZone) {}

  ngOnChanges(){
    // Cargar el script de Google Maps
    this.initGoogleMapsAPI();
    this.showAlert = true;
  }

  // Inicializar la API de Google Maps
  initGoogleMapsAPI() {
    if (!window['google']) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB5ia-uuwbrk59z6iubZULpe4x_bTJVCRQ&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => this.initAutocomplete();
      document.head.appendChild(script);
    } else {
      this.initAutocomplete();
    }
  }

  initAutocomplete() {
    if (!this.center.address) {
      this.setCurrentLocation();
    }
    this.geoCoder = new google.maps.Geocoder;
  }

  // Obtener las coordenadas de la ubicación actual
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center.address.latitude = position.coords.latitude;
        this.center.address.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.center.address.latitude, this.center.address.longitude);
      });
    }
  }

  markerDragEnd(event: any) {
    let latitude = event.latLng.lat();
    let longitude = event.latLng.lng();
    this.getAddress(latitude, longitude);
    this.center.address.latitude = latitude;
    this.center.address.longitude = longitude;
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.place.address = results[0].address_components;
          this.getgoogleAddress(results[0]);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  showInfo(infoWindow) {}

  hideAlert(){
    this.showAlert = false;
  }

  // Modificar la función getgoogleAddress para evitar errores de undefined
  getgoogleAddress(address: any){
    const addressComponents = address.address_components || [];
    console.log(addressComponents.length);
    switch(addressComponents.length){
      case 1: break;
      case 2: break;
      case 3: break;
      case 4:
        this.center.address.number = '';
        this.center.address.street = addressComponents[0]?.long_name || '';
        this.center.address.council = addressComponents[1]?.long_name || '';
        this.center.address.province = addressComponents[2]?.long_name || '';
        this.center.address.postalCode = addressComponents[3]?.long_name || '';
        break;

      case 5:
        if(addressComponents[0]?.types[0] == 'route'){
          console.log("entre aqui");
          this.center.address.number = '';
          this.center.address.street = addressComponents[0]?.long_name || '';
          this.center.address.council = addressComponents[1]?.long_name || '';
          this.center.address.province = addressComponents[2]?.long_name || '';
          this.center.address.community = addressComponents[3]?.long_name || '';
          this.center.address.country = addressComponents[4]?.long_name || '';
        } else {
          this.center.address.number = '';
          this.center.address.postalCode = addressComponents[0]?.long_name || '';
          this.center.address.council = addressComponents[1]?.long_name || '';
          this.center.address.province = addressComponents[2]?.long_name || '';
        }
        break;

      case 6:
        if(addressComponents[0]?.types[0] == 'street_number'){
          this.center.address.number = addressComponents[0]?.long_name || '';
          this.center.address.street = addressComponents[1]?.long_name || '';
          this.center.address.council = addressComponents[2]?.long_name || '';
          this.center.address.province = addressComponents[2]?.long_name || '';
          this.center.address.community = addressComponents[3]?.long_name || '';
          this.center.address.country = addressComponents[4]?.long_name || '';
          this.center.address.postalCode = addressComponents[5]?.long_name || '';
        } else {
          this.center.address.number = '';
          this.center.address.street = addressComponents[0]?.long_name || '';
          this.center.address.council = addressComponents[1]?.long_name || '';
          this.center.address.province = addressComponents[2]?.long_name || '';
        }
        break;

      default:
        this.center.address.number = addressComponents[0]?.long_name || '';
        this.center.address.street = addressComponents[1]?.long_name || '';
        this.center.address.council = addressComponents[2]?.long_name || '';
        this.center.address.province = addressComponents[3]?.long_name || '';
    }
    console.log(address);
    console.log(this.center);
  }
}
