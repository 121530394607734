import { Component, OnInit } from '@angular/core';
import {SharingService} from "../../../services/sharing.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {LoginService} from "../../../services/login.service";

// Interface estructura objeto User
export interface UserLogin {
  user: string,
  password: string,
  tenant: string
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  loading = false;
  userData: UserLogin | undefined;
  tenant: string | null = null;
  companyId: string | null = null;
  loginErrorMsg: string = "";

  constructor(
    public _ss: SharingService,
    private fb: FormBuilder,
    private router: Router,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private _ls: LoginService
  ) {
    this._ss.currentRoute = "login";
    this.form = this.fb.group({
      user: ['', Validators.required],
      password: ['', Validators.required],
      tenant: ['', Validators.required]
    })
  }

  ngOnInit(): void {

    this.tenant = this.route.snapshot.paramMap.get('tenant');
    if (this.tenant) {
      this.form.patchValue({tenant: this.tenant});
      this.form.get('tenant')?.disable();
    } else {
      this.form.get('tenant')?.enable();
    }
    this.companyId = this.route.snapshot.paramMap.get('company');
  }

  enterLogin(){
    this.loginErrorMsg = "";
    this.userData = {
      user: this.form.value.user,
      password: this.form.value.password,
      tenant: this.form.value.tenant
    }

    if(!this.userData.tenant && this.tenant){
      this.userData.tenant = this.tenant;
    }
    this.loading = true;
    if(this.userData.user){
      this._ls.requestLogin(this.userData).subscribe((response) =>{
        debugger;
        if(response){
          let data = JSON.parse(response);
          if(data.data){

            /*this.router.navigate(['configuracion/' + this.companyId]);
            this._ss.currentRoute = "configuracion";*/

            let url = window.location.origin + "/#/configuracion/" + this.companyId;
            window.location.replace(url);
            location.reload();
          }
          else{
            this.loading = false;
            this.loginErrorMsg = "Credenciales incorrectas.";
          }
        }else{
          console.log("Se ha producido un error");
          console.log(response.msg);
          this.loading = false;
          this._snackBar.open('Credenciales incorrectas. Por favor, inténtelo de nuevo.', 'Cerrar', {
            duration: 3000,
          });
        }
      });


      /*let urlDriver = environment.external.services.driver*/


      /*if(environment.testCredentials){
        this.userData = {
          user: environment.testCredentials.user,
          password: environment.testCredentials.password,
          tenant: environment.testCredentials.tenant
        }
      }*/


      /*this._userDataService.login(this.userData).subscribe((response) => {
        this.loading = false;
        if(response.data != null){
          //this.userId = response.data.userId;
          this._driverDataService.getCompanyDataDriver().subscribe((response) => {
            this.companyData = JSON.parse(response.response);

            if(this.companyData != undefined){
              if(this.companyData.id == 23 || this.companyData.id == 190){
                this._userDataService.colorTemplate = 2;
                document.documentElement.style.setProperty("--theme-color", "#df0714");
              }

              // Si es la empresa producción/motrio pone el color de motrio
              else if(this.companyData.id == 23){
                document.documentElement.style.setProperty("--theme-color", "#62676d");
                this._userDataService.colorTemplate = 3;
              }
              else{
                this._userDataService.colorTemplate = 1;
              }
            }
            this.router.navigate(['users']);

          })
        }
        else if(response.errors.length > 0){
          let errorCode = response.errors[0].extensions.errorCode;
          let errorText = "";
          switch(errorCode){
            case "ERR_LOGIN_INVALID_CREDENTIALS":
              errorText = "Usuario o contraseña incorrectos";
              break;
            case "ERR_LOGIN_INVALID_TENANT":
              errorText = "El tenant no es válido";
              break;
            default:
              errorText = "Ha sucedido un error";
          }
          this.loginError(errorText);
        }
      });*/
    }
    else{
      this.loginError("Debe rellenar todos los campos");
      //this.form.reset();
    }
  }

  clearErrorMessage(){
    this.loginErrorMsg = "";
  }

  loginError(error: string){
    this._snackBar.open(error, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    });
  }

}
