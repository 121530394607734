import { Injectable } from '@angular/core';
import { SharingService } from './sharing.service';
import { HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent, HttpParameterCodec
}       from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/timeout';
import { Promotion } from '../models/promotion';
import { URL } from '../config';
import { Vehicle } from '../models/vehicle';
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ApserviceService {

  private publicURL = URL.host_backend + URL.endpoint_public;  // URL to web api
  private adminUrl = URL.host_backend + URL.endpoint_admin;
  public driverURL =  URL.host_backend + URL.endpoint_driver;
  public imgURL =  URL.image_url; //URL static upload folder


  constructor(
    private http: HttpClient,
    private _ss: SharingService
  ) { }


  private getHeadersPublic() {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('api-key', '@?Uq?DKYLd3bY_e*N%L46YKQ%M!ZcwABKAS_%mRBy%KD8D7#dagx5*A*hv2UR3RwufLvraDnxYmS7m+dE!_nybvNg^bZgHQbZ=Yp#g#Y6yDgLY7H!dmH=ZEuZf#A9yRD^kLw8H8s9MGQxCCWLWHN6-CCFYY$@uC24EXp*gm3==bLWvM6MUm%p9kkSyhNqegv3@qW@DN%?ux4zcLCv$W8V=CbBJ4cS@XA%CBwEA=gW?6CKMUE^%ACB_&*EchqV#Sq');
    headers.append('Authorization', this._ss.getWorkShopSettings() ? this._ss.getWorkShopSettings().token : '');
    return headers;
  }

  //Devolver los dominios del taller
  getWorkshopDomains(): Observable<any> {
    const workshopSettings = this._ss.getWorkShopSettings();
    const url = `${this.publicURL}domains/${workshopSettings.id_workshop}`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  // Devolver marcas del taller
  getWorkshopBrands(idWorkshop: string): Observable<any> {
    const url = `${this.publicURL}settings/${idWorkshop}/brands/`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

   //Devolver los dominios del taller
  setDomainStatus(id: string): Observable<any> {
    const url = `${this.adminUrl}domain/`;
    const payload = { id };

    return this.http.put(url, payload, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  //Devolver la configuración de cada dominio
  getWorkshopDomainSettings(id: string): Observable<any> {
    const url = `${this.publicURL}domains/${id}/settings/`;

    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  //Cargar plantillas
  getTemplates(id: number): Observable<any> {
    const url = `${this.publicURL}templates/brand/${id}`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  //Crear la configuración básica del taller
  addMultisiteSettings(settings: any): Observable<any> {
    const payload = {
      id_workshop: this._ss.getWorkShopSettings().id_workshop,
      id_domain: settings.id_domain,
      site_title: settings.site_title,
      site_description: settings.site_description,
      phone: settings.phone,
      email: settings.email,
      timetable: settings.timetable,
      address: settings.address,
      logo: settings.logo
    };
    const url = `${this.adminUrl}settings/`;
    return this.http.post(url, payload, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  //Modicar configuración básica del taller
  setMultisiteSettings(settings: any): Observable<any> {
    const payload = {
      id: settings.id,
      id_brand_template: settings.id_brand_template,
      site_title: settings.site_title,
      site_description: settings.site_description,
      phone: settings.phone,
      email: settings.email,
      timetable: settings.timetable,
      address: settings.address,
      logo: settings.logo
    };
    const url = `${this.adminUrl}settings/`;
    return this.http.put(url, payload, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  // Cargar todas las promociones
  getMultisitePromotions(): Observable<any> {
    const workshopSettings = this._ss.getWorkShopSettings();
    const url = `${this.publicURL}promotions/${workshopSettings.id_workshop}/brand/${workshopSettings.id_brand}`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  // Cambiar el estado de una promoción
  changePromotionStatus(id: number): Observable<any> {
    const url = `${this.adminUrl}promotion/`;
    const payload = { id: id };
    return this.http.put(url, payload, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  // Cambiar el estado de una promoción
  changePromotionFeatured(id: number): Observable<any> {
    const url = `${this.adminUrl}promotion/featured/`;
    const payload = { id: id };
    return this.http.put(url, payload, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  // Eliminar una promoción
  deletePromotion(id: number): Observable<any> {
    const url = `${this.adminUrl}promotion/${id}`;
    return this.http.delete(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  // Añadir una promoción
  addPromotion(promotion: Promotion): Observable<any> {
    const url = `${this.adminUrl}promotion/`;
    const payload = {
      id_workshop: this._ss.getWorkShopSettings().id_workshop,
      id_brand: this._ss.getWorkShopSettings().id_brand,
      id: promotion.id,
      title: promotion.title,
      description: promotion.description,
      image: promotion.image,
      banner_image: promotion.banner_image,
      start_date: promotion.start_date,
      expiration_date: promotion.expiration_date,
      status: promotion.status
    };
    return this.http.post(url, payload, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  // Cargar todas las marcas
  getBrands(): Observable<any> {
    const url = `${this.publicURL}brands/`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  // Cargar los servicios de la marca
  getServices(): Observable<any> {
    const url = `${this.publicURL}services/${this._ss.getWorkShopSettings().id_workshop}`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  // Cargar los servicios de la marca
  getBrandServices(id: string): Observable<any> {
    const url = `${this.publicURL}brand/${id}/services/`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  //Modicar servicios del taller
  setWorkShopServices(services: any): Observable<any> {
    const url = `${this.adminUrl}services/`;
    const payload = {
      services: services,
      id_workshop: this._ss.getWorkShopSettings().id_workshop
    };
    return this.http.put(url, payload, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  // Cargar todas las aseguradoras
  getPartners(): Observable<any> {
    const url = `${this.publicURL}partners/`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  // Cargar las aseguradoras de un taller
  getWorkshopPartners(): Observable<any> {
    const workshopSettings = this._ss.getWorkShopSettings();
    const url = `${this.publicURL}workshop/${workshopSettings.id_workshop}/brand/${workshopSettings.id_brand}/partners`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  // Modificar Aseguradoras
  setWorkShopPartners(partners: any): Observable<any> {
    const workshopSettings = this._ss.getWorkShopSettings();
    const url = `${this.adminUrl}partners/`;
    const payload = {
      partners: partners,
      id_workshop: workshopSettings.id_workshop,
      id_brand: workshopSettings.id_brand
    };
    return this.http.put(url, payload, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  getWorkShopCenters(): Observable<any> {
    const url = `${this.driverURL}workshop/${this._ss.getWorkShopSettings().id_workshop}/centers`;

    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  deleteWorkshopCenter(id: string): Observable<any> {
    const url = `${this.adminUrl}center/${id}`;

    return this.http.delete(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  getPagesByWorkshopId(): Observable<any> {
    const workshopSettings = this._ss.getWorkShopSettings();
    const url = `${this.publicURL}workshop/${workshopSettings.id_workshop}/brand/${workshopSettings.id_brand}/pages`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  addPagestoWorkshop(): Observable<any> {
    const workshopSettings = this._ss.getWorkShopSettings();
    const url = `${this.adminUrl}pages/`;
    const payload = {
      id_workshop: workshopSettings.id_workshop
    };
    return this.http.post(url, payload, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  getPageDetailByPageTypeForWorkshop(idPageType: number): Observable<any> {
    const workshopSettings = this._ss.getWorkShopSettings();
    const url = `${this.publicURL}page/${idPageType}/workshop/${workshopSettings.id_workshop}/brand/${workshopSettings.id_brand}`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  setWorkshopPolicies(policies: any): Observable<any> {
    const url = `${this.adminUrl}policies/`;
    const payload = { policies: policies };
    return this.http.put(url, payload, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  // Método para cargar los centros del Workhshop
  getWorkshopCenters(): Observable<any> {
    const url = `${this.driverURL}workshop/${this._ss.getWorkShopSettings().id_workshop}/centers`;

    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  // Método para guardar un centro
  setWorkShopCenter(center: any): Observable<any> {
    const url = `${this.driverURL}workshop/center`;
    const payload = { center };

    return this.http.put(url, payload, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }


  // Méotod para modificar los valores de una página
  setPageDetails(page: any): Observable<any> {
    const workshopSettings = this._ss.getWorkShopSettings();
    const url = `${this.adminUrl}pagedetail/`;
    const payload = {
      id: page.id,
      id_page_type: page.id_page_type,
      id_workshop: workshopSettings.id_workshop,
      name: page.name,
      banner_title: page.banner_title,
      banner_image: page.banner_image,
      content_title: page.content_title,
      content_subtitle: page.content_subtitle,
      content_description: page.content_description,
      content_image: page.content_image
    };
    return this.http.put(url, payload, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  //Método para Activar / Desactivar una página
  setActivePage(page: any): Observable<any> {
    const url = `${this.adminUrl}pagedetail/active`;
    const payload = {
      id: page.id,
      id_page_type: page.id_page_type,
      id_workshop: this._ss.getWorkShopSettings().id_workshop,
      id_brand: this._ss.getWorkShopSettings().id_brand,
      active: page.active
    };
    return this.http.put(url, payload, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  getSocialNetworkTypes(): Observable<any> {
    const url = `${this.publicURL}socialnetworks`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  getSocialNetworks(): Observable<any> {
    const workshopSettings = this._ss.getWorkShopSettings();
    const url = `${this.publicURL}workshop/${workshopSettings.id_workshop}/brand/${workshopSettings.id_brand}/socialnetworks`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  setSocialNetworks(socialnetworks: any): Observable<any> {
    const url = `${this.adminUrl}socialnetworks/`;
    const payload = {
      socialnetworks: socialnetworks,
      id_workshop: this._ss.getWorkShopSettings().id_workshop
    };
    return this.http.put(url, payload, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  getWorkshopSliders(): Observable<any> {
    const workshopSettings = this._ss.getWorkShopSettings();
    const url = `${this.publicURL}workshop/${workshopSettings.id_workshop}/brand/${workshopSettings.id_brand}/slider`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  getWorkShopBanners(): Observable<any> {
    const workshopSettings = this._ss.getWorkShopSettings();
    const url = `${this.publicURL}workshop/${workshopSettings.id_workshop}/brand/${workshopSettings.id_brand}/banner`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  /** Vo Services */

  getVoVehicles(): Observable<any> {
    const workshopSettings = this._ss.getWorkShopSettings();
    const url = `${this.publicURL}workshop/${workshopSettings.id_workshop}/brand/${workshopSettings.id_brand}/vehicles`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  getVoVehicle(id: string): Observable<any> {
    const url = `${this.publicURL}workshop/${this._ss.getWorkShopSettings().id_workshop}/brand/${this._ss.getWorkShopSettings().id_brand}/vehicles/${id}`;

    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  addVoVehicle(vehicle: Vehicle): Observable<any> {
    const url = `${this.adminUrl}vehicle/`;
    const payload = {
      ...vehicle,
      id_workshop: this._ss.getWorkShopSettings().id_workshop,
      id_brand: this._ss.getWorkShopSettings().id_brand
    };
    return this.http.post(url, payload, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  modifyVoVehicle(vehicle: Vehicle): Observable<any> {
    const url = `${this.adminUrl}vehicle/${vehicle.id}`;
    const payload = { vehicle };

    return this.http.put(url, payload, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  deleteVoVehicle(vehicle: Vehicle): Observable<any> {
    const url = `${this.adminUrl}vehicle/${vehicle.id}`;

    return this.http.delete(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  changeSliderStatus(id: string): Observable<any> {
    const url = `${this.adminUrl}slider/status`;
    return this.http.put(url, { id: id }, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response), // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  changeBannerStatus(id: string): Observable<any> {
    const url = `${this.adminUrl}banner/status`;
    return this.http.put(url, { id: id }, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  deleteSlider(id: string): Observable<any> {
    const url = `${this.adminUrl}slider/${id}`;
    return this.http.delete(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  modifySlider(slider: any): Observable<any> {
    const url = `${this.adminUrl}slider/`;
    const payload = {
      slider: slider
    };
    return this.http.put(url, payload, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  addSlider(slider: any): Observable<any> {
    const url = `${this.adminUrl}slider/`;
    const payload = {
      slider: slider
    };
    return this.http.post(url, payload, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  modifyBanner(banner: any): Observable<any> {
    const url = `${this.adminUrl}banner/`;
    const payload = {
      banner: banner
    };
    return this.http.put(url, payload, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  addBanner(banner: any): Observable<any> {
    const url = `${this.adminUrl}banner/`;
    const payload = {
      banner: banner
    };
    return this.http.post(url, payload, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  deleteBanner(id: string): Observable<any> {
    const url = `${this.adminUrl}banner/${id}`;
    return this.http.delete(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  private handleError(error: any): Observable<never> {
    console.error('An error occurred', error);
    return throwError(() => new Error(error.message || error));
  }

}
