import { Component, OnInit, Input, HostListener, ViewChild, ElementRef, NgZone } from '@angular/core';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { ApserviceService } from 'src/app/services/apservice.service';
import { Center } from 'src/app/models/center';
import { NotifierService } from 'angular-notifier';
import { AngularEditorConfig } from '@kolkov/angular-editor';

declare var google: any;

@Component({
  selector: 'app-modify-center',
  templateUrl: './modify-center.component.html',
  styleUrls: ['./modify-center.component.scss']
})
export class ModifyCenterComponent implements OnInit{

  @HostListener('document:click', ['$event'])
  clickOn(event) {
    if(event.target.className == 'btn-center'){
      this.isOpen = true;
    }
  }

  autocompleteAddress;
  isOpen = false;
  faArrowRight = faArrowRight;

  place = {
    address: [],
    latitude: null,
    longitude: null,
  };
  title: string;
  draggable = false;
  iconUrl: string;
  visible = true;
  openInfoWindow = true;
  opacity = 1;
  zIndex = 1;
  clickable = true;
  zoom: number = 6;
  private geoCoder;

  @Input() center: Center;
  @ViewChild('search')
  public searchElementRef: ElementRef;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Introduce una descripción...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };

  constructor(
    private ngZone: NgZone,
    private _ap: ApserviceService,
    private _ns: NotifierService
  ) { }

  ngOnInit(): void {
    this.initGoogleMapsAPI();
  }

  initGoogleMapsAPI() {
    if (!window['google']) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB5ia-uuwbrk59z6iubZULpe4x_bTJVCRQ&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => this.initAutocomplete();
      document.head.appendChild(script);
    } else {
      this.initAutocomplete();
    }
  }

  initAutocomplete() {
    this.geoCoder = new google.maps.Geocoder;
    const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        let latitude = place.geometry.location.lat();
        let longitude = place.geometry.location.lng();
        this.zoom = 12;
        this.place.address = place.address_components;
        this.center.address.latitude = latitude;
        this.center.address.longitude = longitude;
        console.log(latitude);
        console.log(longitude);
        console.log(place);
        console.log(this.center);
        this.getgoogleAddress(this.place);
      });
    });
  }

  showCenter(){
    this.isOpen = !this.isOpen;
  }

  saveCenter(){
    console.log(this.center);
    this._ap.setWorkShopCenter(this.center).subscribe((response)=>{
      if(response.result == 'OK'){
        this._ns.notify("success", "Guardado correctamente!");
        this.isOpen = false;
        console.log(response.msg);
      }else{
        this._ns.notify("error", "Error: Se ha producido un error en el guardado!");
        console.log("Se ha producido un error!");
        console.log(response.msg);
      }
    });
  }

  sendAutocompleteAddress(){
    this.autocompleteAddress = this.center.address+', '+this.center.address.number+ ', '+this.center.address.council+', '+this.center.address.province+', Spain';
  }

  changeLatitude(){

  }

  changeLongitude(){

  }

  getgoogleAddress(address: any){
    console.log(address.address.length);
    switch(address.address.length){
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        this.center.address.number = '';
        this.center.address.street = address.address[0].long_name;
        this.center.address.council = address.address[1].long_name;
        this.center.address.province = address.address[2].long_name;
        this.center.address.postalCode = address.address[3].long_name;
        break;

      case 5:
        if(address.address[0].types[0] == 'route'){
          console.log("entre aqui");
          this.center.address.number = '';
          this.center.address.street = address.address[0].long_name;
          this.center.address.council = address.address[1].long_name;
          this.center.address.province = address.address[2].long_name;
          this.center.address.community = address.address[3].long_name;
          this.center.address.country = address.address[4].long_name;
        }else{
          this.center.address.number = '';
          this.center.address.postalCode = address.address[0].long_name;
          this.center.address.council = address.address[1].long_name;
          this.center.address.province = address.address[2].long_name;
        }
        break;

      case 6:
        if(address.address[0].types[0] == 'street_number'){
          this.center.address.number = address.address[0].long_name;
          this.center.address.street = address.address[1].long_name;
          this.center.address.council = address.address[2].long_name;
          this.center.address.province = address.address[2].long_name;
          this.center.address.community = address.address[3].long_name;
          this.center.address.country = address.address[4].long_name;
          this.center.address.postalCode = address.address[5].long_name;
        }else{
          this.center.address.number = '';
          this.center.address.street = address.address[0].long_name;
          this.center.address.council = address.address[1].long_name;
          this.center.address.province = address.address[2].long_name;
        }
        break;
      default:
        this.center.address.number = address.address[0].long_name;
        this.center.address.street = address.address[1].long_name;
        this.center.address.council = address.address[2].long_name;
        this.center.address.province = address.address[3].long_name;
    }

    console.log(address);
    console.log(this.center);
  }

}
