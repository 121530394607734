export class Vehicle {
    id: number;
    id_workshop: number;
    id_brand: number;
    brand: string;
    model: string;
    version: string;
    bodywork: string;
    doors: number;
    fuel: string;
    transmission: string;
    plate: string;
    first_date_plate: string;
    watios: number;
    kilometers: number;
    engine: string;
    color: string;
    warranty: string;
    description: string;
    equipment: { [key: string]: any }[];
    accessories: { [key: string]: any }[];
    extras: { [key: string]: any }[];
    images: string[];
    price: number;
    offered_price: number;
    featured: number;
    active: number;
    created_at: Date;
    modified_at: Date;

    constructor(v?: Vehicle) {
      this.id = v?.id || null;
      this.id_workshop = v?.id_workshop || null;
      this.id_brand = v?.id_brand || null;
      this.brand = v?.brand || "";
      this.model = v?.model || "";
      this.version = v?.version || "";
      this.bodywork = v?.bodywork || "";
      this.doors = v?.doors || 0;
      this.fuel = v?.fuel || "";
      this.transmission = v?.transmission || "";
      this.plate = v?.plate || "";
      this.first_date_plate = v?.first_date_plate || "";
      this.watios = v?.watios || 0;
      this.kilometers = v?.kilometers || 0;
      this.engine = v?.engine || "";
      this.color = v?.color || "";
      this.warranty = v?.warranty || "";
      this.description = v?.description || "";
      this.equipment = v?.equipment || [];
      this.accessories = v?.accessories || [];
      this.extras = v?.extras || [];
      this.images = v?.images || [];
      this.price = v?.price || 0;
      this.offered_price = v?.offered_price || 0;
      this.featured = v?.featured || 0;
      this.active = v?.active || 0;
      this.created_at = v?.created_at || new Date();
      this.modified_at = v?.modified_at || new Date();
    }
  }

