<div class="card-vehicle">
    <div class="banner-vehicle" [ngStyle]="{'background-image': 'url(' + (_ss.imgURL + (vehicle.images && vehicle.images.length > 0 ? vehicle.images[0] : '../../../../assets/image/no_imagen.png')) + ')'}">
        <span [ngClass]="{'ribbon': !vehicle.active, 'ribbon-active': vehicle.active }" (click)="updateStatus(!vehicle.active, vehicle.featured)">
            {{vehicle.active ? "Activado" : "Desactivado"}}
        </span>
        <span class="star" [ngClass]="{featured: vehicle.featured}" (click)="updateStatus(vehicle.active, !vehicle.featured)">
            <fa-icon [icon]="faStar"></fa-icon>
        </span>
            <span class="close" (click)="delPromotion(vehicle)">
            <fa-icon [icon]="faClose"></fa-icon>
        </span>
    </div>
    <div class="vehicle-details">
    <div>
        <h1>{{vehicle.brand}} {{vehicle.model}} {{vehicle.version}}</h1>
    </div>
    <div class="list-prices flex column">
        <ng-container *ngIf="vehicle.offered_price">
            <del>{{ vehicle.price }}€</del> <span class="price">{{ vehicle.offered_price }}€</span>
        </ng-container>
        <ng-container *ngIf="!vehicle.offered_price">
            <span class="price">{{ vehicle.price }}€</span>
        </ng-container>
    </div>    
    <div>
        <span *ngIf="vehicle.first_date_plate">{{ vehicle.first_date_plate | yearOnly }}</span>
        <span *ngIf="vehicle.kilometers">&nbsp;| {{vehicle.kilometers | number:'1.0-0'}}km</span>
        <span *ngIf="vehicle.watios">&nbsp;| {{vehicle.watios}}CV</span>
        <span *ngIf="vehicle.transmission">&nbsp;| {{vehicle.transmission}}</span>
    </div>
    <div *ngIf="vehicle.fuel">
        {{vehicle.fuel}}
    </div>
    </div>
    <button (click)="openForm()">
        Editar coche
    </button>
</div>
