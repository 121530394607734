import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';
import { ApserviceService } from 'src/app/services/apservice.service';
import { Vehicle } from 'src/app/models/vehicle';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-vo',
  templateUrl: './vo.component.html',
  styleUrls: ['./vo.component.scss']
})
export class VoComponent implements OnInit {
  @ViewChild('iframe') iframe: ElementRef;
  token;
  public isLoading: boolean = false;
  public vehicle: Vehicle = new Vehicle();
  public vehicles = [];
  public isFormOpen: boolean = false;
  variableCambiante: any;
  private variableSubscription: Subscription;

  constructor(
    public _ss: SharingService,
    private _ap: ApserviceService
  ) {
    this._ss.titleSection = 'VO';
  }

  ngOnInit() {
    // Suscríbete al observable para escuchar cambios en selectedBrandId
    this.variableSubscription = this._ss.selectedBrandId$.subscribe((value) => {
      this.variableCambiante = value;
      // Realiza aquí tu acción, como hacer un fetch de datos
      this.getVoVehicles();
    });
  }

  getVoVehicles() {
    this._ap.getVoVehicles().subscribe({
      next: (response) => {
        if (response.result === 'OK') {
          this.vehicles = response.data;
        }
      }
    });
  }

  openFormWithVehicle(vehicle: Vehicle) {
    this.isFormOpen = true;
    this.vehicle = vehicle;
    console.log(this.vehicle);
  }

  onFormClosed($event) {
    console.log("ejecutando el evento", $event);
    this.isFormOpen = $event; // Cierra el formulario cuando se hace clic en "Cerrar" en VoForm
  }

  onDeleteVehicle(){
    this.getVoVehicles();
    this.isFormOpen = false;
  }

  onUpdateVehicle() {
    this.getVoVehicles();
  }

  ngOnDestroy() {
    // No olvides desuscribirte cuando el componente se destruye
    this.variableSubscription.unsubscribe();
  }
}
