import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';
import { social } from 'src/app/constants';
import { ApserviceService } from 'src/app/services/apservice.service';
import { NotifierService } from 'angular-notifier';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-socialnetworks',
  templateUrl: './socialnetworks.component.html',
  styleUrls: ['./socialnetworks.component.scss']
})
export class SocialnetworksComponent implements OnInit, OnDestroy {

  socialTypes;
  socialnetworks = social;
  saveSubscription;
  variableCambiante: any;
  private variableSubscription: Subscription;

  constructor(
    public _ss: SharingService,
    private _ap: ApserviceService,
    private _ns: NotifierService
  ) {
    this._ss.titleSection = 'Redes Sociales';
    this._ss.getLocalStorage();
  }

  ngOnInit(): void {
    this._ss.saveButton = true;
    this._ss.newButton = false;
    this.saveSubscription = this._ss.$save.subscribe(() => {
      this.save();
    });

    this.variableSubscription = this._ss.selectedBrandId$.subscribe((value) => {
      this.variableCambiante = value;
      // Realiza aquí tu acción, como hacer un fetch de datos
      this.getSocialTypes();
    });
  }

  getSocialTypes(){
    this._ap.getSocialNetworkTypes().subscribe({
      next: (response) => {
        if (response.result == 'OK') {
          console.log(response.msg);
          this.socialTypes = response.data;
          this.socialTypes.forEach(type => {
            let i = this.socialnetworks.findIndex(x => x.id_social_network == type.id);
            if (i != -1) {
              type.id_social_network = type.id;
              type.id = null;
              type.status = false;
              type.link = '';
              type.image = this.socialnetworks.find(x => x.id_social_network == type.id_social_network).image;
            }
          });
          this.getSocialNetworks();
        } else {
          console.log(response.msg);
          console.log("Se ha producido un error!");
        }
      }
    });
  }

  getSocialNetworks(){
    this._ap.getSocialNetworks().subscribe({
      next: (response) => {
        console.log(response);
        if (response.result == 'OK') {
          response.data.forEach(social => {
            console.log(social);
            this.socialTypes.find(x => x.id_social_network == social.id_social_network).id = social.id;
            this.socialTypes.find(x => x.id_social_network == social.id_social_network).link = social.link;
            if (social.status == 1) {
              this.socialTypes.find(x => x.id_social_network == social.id_social_network).status = true;
            } else {
              this.socialTypes.find(x => x.id_social_network == social.id_social_network).status = false;
            }
          });
          console.log(this.socialnetworks);
        } else {
          console.log('Se ha producido un erro al cargar las redes sociales');
          console.log(response.msg);
        }
      }
    });
  }

  save(){
    console.log(this.socialTypes);
    this._ap.setSocialNetworks(this.socialTypes).subscribe({
      next: (response) => {
        console.log(response);
        if (response.result == 'OK') {
          this._ns.notify("success", "Guardado correctamente!");
          this.getSocialNetworks();
        } else {
          this._ns.notify("error", "Oops! Se ha producido un error al actualizar.");
        }
      }
    });
  }

  ngOnDestroy(){
    this.saveSubscription.unsubscribe();
    this.variableSubscription.unsubscribe();
  }

}
