import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharingService } from './sharing.service';
import { URL } from '../config';
import { Setting } from '../models/settings';
import { ApserviceService } from './apservice.service';

@Injectable({
  providedIn: 'root'
})
export class AppconfigService {
  domain;
  id_workshop;
  private publicURL = URL.host_backend + URL.endpoint_public;  // URL to web api

  constructor(
    public _http: HttpClient,
    public _ss: SharingService,
    private _ap: ApserviceService
  ) {
  }



public loadConfig(id_workshop) {
    console.log('Cargando configuración site...');
    return this._http.get(this.publicURL+'settings/'+id_workshop)
    .toPromise()
    .then((response: any) => {
      console.log(response.data[0]);
        this._ss.workshopData = response.data[0];
        this._ss.setLocalStorage(JSON.stringify(this._ss.workshopData));
        this.getDomains();
    })
    .catch((err: any) => {
        console.error(err);
    })
  }


  getDomains(){
    this._ap.getWorkshopDomains().subscribe({next:(response)=>{
      if(response.result == 'OK'){
        console.log(response.data);
        this._ss.workshopData.domains = response.data;
      }else{
        console.log("Se ha producido un error");
      }
    }});
  }

  // Obtiene resultados de entidad tercero filtrando por nombre, apellidos, nombre comercial, dni, emails y teléfonos
/*  this._thirdService.getThirds('(name==' + input.value + '*) or (surname==' + input.value + '*) or (secondSurname==' + input.value + '*) or (tradeName==' + input.value + '*) or (idDocument==' + input.value + '*) or (contacts.emails.email==' + input.value + '*) or (contacts.phones.number==' + input.value +'*)', 10, 0)
.subscribe({
             next: (data: DriverJSONResponseListThirdDto) => {
  this.thirdsData = data;
},
error: (err: any) => {
  console.error('Error fetching thirds', err);
}
});*/

}
