import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';
import { ApserviceService } from 'src/app/services/apservice.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { NotifierService } from 'angular-notifier';
import { Page } from 'src/app/models/page';
import { PAGE_TYPES_IDS } from 'src/app/constants';
import { Subscribable, Subscription } from 'rxjs';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit, OnDestroy {

  partners = [];
  page:Page = {
    id: null,
    id_page_type: null,
    id_workshop: null,
    id_brand: null,
    name: null,
    active:false,
    banner_image: null,
    banner_title: null,
    content_description: null,
    content_image:null,
    content_title: null
  };
  saveSubscription;
  variableCambiante: any;
  private variableSubscription: Subscription;

  constructor(
    public _ss: SharingService,
    private _ap: ApserviceService,
    private _ns:NotifierService
  ) {
    this.page.id_page_type = PAGE_TYPES_IDS.PARTNER_PAGE_TYPE_ID;
    this._ss.titleSection = 'Colaboradores';
  }

  ngOnInit(): void {
    this._ss.saveButton = true;
    this._ss.newButton = false;
    this._ss.getLocalStorage();
    this.saveSubscription = this._ss.$save.subscribe(() => {
      this.savePartners();
    });

    this.variableSubscription = this._ss.selectedBrandId$.subscribe((value) => {
      this.variableCambiante = value;
      // Realiza aquí tu acción, como hacer un fetch de datos
      this.getPartners();
      this.getPagePartner();
    });
  }

  drop(event: CdkDragDrop<any>) {
    this.partners[event.previousContainer.data.index]=event.container.data.partner
    this.partners[event.container.data.index]=event.previousContainer.data.partner
  }

  getPagePartner(){
    this._ap.getPageDetailByPageTypeForWorkshop(this.page.id_page_type).subscribe({
      next: (response) => {
        if (response.result == 'OK') {
          console.log(response.msg);
          this.page = response.data[0];
          console.log(this.page);
        } else {
          console.log("Se ha producido un error", response.msg);
        }
      }, error: (err) => {
        console.log("Se ha producido un err", err);
      }
    });
  }

  setPageActive(){
    this.page.active = !this.page.active;
    this._ap.setActivePage(this.page).subscribe({
      next: (response) => {
        if (response.result == 'OK') {
          if (this.page.active) {
            this._ns.notify('success', 'Colaboradores activado correctamente');
          } else {
            this._ns.notify('success', 'Colaboradores desactivado correctamente');
          }
        } else {
          console.log(response.msg);
          console.log("Se ha producido un error");
        }
      }, error: (err) => {
        console.log("Se ha producido un error", err);
      }
    });
  }

  getPageDetails(){
    this._ap.getPageDetailByPageTypeForWorkshop(this.page.id_page_type).subscribe({
      next: (response) => {
        if (response.result == 'OK') {
          this.page = response.data;
        }
      }, error: (err) => {
        console.log("Se ha producido error", err);
      }
    })
  }


  getPartners(){
    this._ap.getPartners().subscribe({
      next: (response) => {
        if (response.result == 'OK') {
          console.log(response.msg);
          this.partners = response.data;
          this.partners.forEach(element => {
            element.id_partner = element.id;
            element.status = false;
          });
          this.getWorkshopPartners();
        } else {
          console.log("Se ha producido un error");
          console.log(response.msg);
        }
      }
    });
  }

  getWorkshopPartners(){
    this._ap.getWorkshopPartners().subscribe({
      next:
        (response) => {
          if (response.result == 'OK') {
            let partners = response.data;
            partners.forEach(element => {
              let pos = this.partners.findIndex(x => x.id_partner == element.id_partner);
              if (pos != -1) {
                this.partners.find(x => x.id_partner == element.id_partner).status = element.status;
                this.partners.find(x => x.id_partner == element.id_partner).order = element.order;
              }
            });
            this.sortPartners();
            console.log(response.msg);
          } else {
            console.log(response.msg);
          }
        }
    });
  }

  sortPartners(){
    this.partners.sort(function (a, b) {
      return a.order -  b.order;
    });
  }

  changePartnerStatus(partner){
    this.partners.find(x => x.id_partner == partner.id).status = !partner.status;
  }

  savePartners(){
    console.log(this.partners);
    this._ap.setWorkShopPartners(this.partners).subscribe({
      next: (response) => {
        if (response.result == 'OK') {
          console.log(response.msg);
          this._ns.notify("success", "Guardado correctamente!");
        } else {
          this._ns.notify("error", "Error: Se ha producido un error en el guardado!");
          console.log(response.msg);
        }
      }
    });
  }

  ngOnDestroy() {
    this.saveSubscription.unsubscribe();
    this.variableSubscription.unsubscribe();
  }

}
