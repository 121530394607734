export const URL = {
    host_backend: 'https://backmultisiteprod.driver360.es/api/v1',
    host_auth: 'https://backmultisiteprod.driver360.es',
    qcar_image_url: 'https://qcar.es/portalvo/public/imagesVehicles/',
    image_url: 'https://multisitestatic.driver360.es/',
    endpoint_public: '/public/',
    endpoint_admin: '/admin/',
    endpoint_driver: '/driver/',
    endpoint_qcar: '/qcar/'
};
