import { Injectable } from '@angular/core';
import {URL} from "../config";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {SharingService} from "./sharing.service";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private publicURL = URL.host_backend + URL.endpoint_public;  // URL to web api
  private adminUrl = URL.host_backend + URL.endpoint_admin;
  public driverURL =  URL.host_backend + URL.endpoint_driver;
  public imgURL =  URL.image_url; //URL static upload folder
  public authUrl = URL.host_auth;


  constructor(
    private http: HttpClient,
    private _ss: SharingService
  ) { }

  private getHeadersPublic() {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('api-key', '@?Uq?DKYLd3bY_e*N%L46YKQ%M!ZcwABKAS_%mRBy%KD8D7#dagx5*A*hv2UR3RwufLvraDnxYmS7m+dE!_nybvNg^bZgHQbZ=Yp#g#Y6yDgLY7H!dmH=ZEuZf#A9yRD^kLw8H8s9MGQxCCWLWHN6-CCFYY$@uC24EXp*gm3==bLWvM6MUm%p9kkSyhNqegv3@qW@DN%?ux4zcLCv$W8V=CbBJ4cS@XA%CBwEA=gW?6CKMUE^%ACB_&*EchqV#Sq');
    headers.append('Authorization', this._ss.getWorkShopSettings() ? this._ss.getWorkShopSettings().token : '');
    return headers;
  }

  requestLogin(data): Observable<any> {
    const url = `${this.authUrl}/auth/login`;
    const payload = {
      tenant: data.tenant,
      user: data.user,
      password: data.password
    };
    return this.http.post(url, payload, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // Asumimos que la respuesta es JSON.
      catchError(this.handleError)
    );
  }

  private handleError(error: any): Observable<never> {
    console.error('An error occurred', error);
    return throwError(() => new Error(error.message || error));
  }

}
