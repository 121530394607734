import { Injectable, EventEmitter } from '@angular/core';
import { URL } from '../config';
import { Setting } from '../models/settings';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SharingService {

  isOpen = false;
  saveButton = false;
  newButton = false;
  storageName = "driver360Source";
  titleSection;
  adminURL = URL.host_backend + URL.endpoint_admin;
  imgURL = URL.image_url;
  currentRoute: string = 'configuration';

  workshopData:Setting;
  selectedBrand: any = "";
  private selectedBrandIdSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  selectedBrandId$: Observable<any> = this.selectedBrandIdSubject.asObservable();

  sliders = [];
  banners = [];
  isNewSlider:boolean = false;
  isNewBanner:boolean = false;
  isNewDomain:boolean = false;

  $save = new EventEmitter();
  $create = new EventEmitter();

  constructor() { }

  setSelectedBrandId(value: any) {
    this.selectedBrandIdSubject.next(value);
  }

  getSelectedBrandId(): Observable<any> {
    return this.selectedBrandIdSubject.value;
  }

  saveEvent(){
    console.log("Guardando....");
    this.$save.emit();
  }

  createEvent(){
    console.log("Creando....");
    this.$create.emit();
  }

  setLocalStorage(dataSource){
    localStorage.setItem(this.storageName, dataSource);
  }

  getLocalStorage(){
    let data = localStorage.getItem(this.storageName);
    this.workshopData = JSON.parse(data);
  }

  getWorkShopSettings():any {
    return this.workshopData;
  }

  clearWorkshopSettings(){
    localStorage.removeItem(this.storageName);
    this.workshopData = null;
  }

}
